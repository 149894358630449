import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/Hero/Container'
import Button from '../components/Hero/Button'
import Reference from '../components/Reference'

const IndexPage = () => (
  <Layout>
    <SEO title="Etusivu" keywords={[`Ensiapukurssi`, 'Potilasasiamies']} />

    <Container isHeroContainer>
      <Button index={1} link={'/cv'} title={'CV & Referenssit'} />
      <Button index={2} link={'/palvelut'} title={'Palvelut'} />
      <Button index={3} link={'/yhteystiedot'} title={'Yhteystiedot'} />
    </Container>
    <p>
      Olen hoitotyön opettaja, ensiavun ja terveystiedon kouluttaja ja sairaanhoitaja YAMK Seikku Pohja.
    </p>
    <p>
      Minulla on yli 20 vuoden työkokemus hoitoalalta ja yli 15 vuoden ajan olen tehnyt töitä päivystysalalla. Työskentelen ajoittain myös muissa hoitoalan yksiköissä kuten esimerkiksi vanhustenhuollossa tai perusterveydenhuollon yksiköissä. 
    </p>
    <p>
      Tarjoan erilaisia ensiapukoulutuksia yrityksille ja yhteisöille. Lisäksi tarjoan erilaisia räätälöityjä ensiapukoulutuksia, defibrilaattorin käyttökoulutuksia sekä erilaisia luentoja hoitoalaan tai terveydenhoitoon liittyen.
    </p>
    <Container>
      <Button link={'/cv#ref1'}>
        <Reference>
          <p>
            "Erinomainen kouluttaja, jonka opetustyyli on mukaansatempaava ja vakaviakin asioita käydään läpi
            huumorinsävytteisesti"
          </p>
          <p>
            <i>- Tatu Pussila, Toimitusjohtaja, PTMovement Oy</i>
          </p>
        </Reference>
      </Button>
      <Button link={'/cv#ref2'}>
        <Reference>
          <p>"Huomaa kouluttajasta, että toimii päivittäin näiden asioiden parissa."</p>
          <p>
            <i>- Jari Kautonen, Työturvallisuuspäällikkö, Kreate</i>
          </p>
        </Reference>
      </Button>
      <Button link={'/cv#ref3'}>
        <Reference>
          <p>"Erinomainen koulutus, joka voitiin rakentaa juuri meidän tarpeiden ympärille. Suosittelen."</p>
          <p>
            <i>- Kimmo Kyllönen, Head of Field Service | After Sales Business Unit, Miele</i>
          </p>
        </Reference>
      </Button>
    </Container>
  </Layout>
)

export default IndexPage
