import React from 'react'
import * as styles from './container.module.css'
import classNames from 'classnames'

const Container = ({children, isHeroContainer}) => (
  <div className={classNames(styles.container, {[styles.heroContainer]: isHeroContainer})}>
    {children}
  </div>
)

export default Container
