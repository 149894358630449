import React from 'react'
import * as styles from './button.module.css'
import classNames from 'classnames'
import {navigate} from 'gatsby'
import {keyboardClickHandler} from '../../core/utils'

const Button = ({index, link, title, children}) => {
  const classes = classNames(styles.button, {[styles[`button${index}`]]: Boolean(index)})
  const handleClick = () => navigate(link)
  return (
    <div
      className={classes}
      onClick={handleClick}
      role="button"
      onKeyDown={(e) => keyboardClickHandler(e, handleClick)}
      tabIndex={0}
    >
      {title && <h1 className={styles.title}>{title}</h1>}
      {children}
    </div>
  )
}

export default Button
